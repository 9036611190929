import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import {serverConfig} from "../config/serverConfig";
import publicRoutes from "./public";

Vue.use(VueRouter);

let AllRoutes = publicRoutes;

const router = new VueRouter({
    mode: "history",
    base: serverConfig.base_name,
    routes: AllRoutes,
    linkExactActiveClass: "active",
    scrollBehavior: function (to) {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.afterEach(() => {
    setTimeout(() => {
        store.dispatch("loader");
    }, 500);
});

export default router;