<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row" id="restauration-card">
    <div
        class="tw-flex tw-flex-col tw-items-start tw-justify-between tw-order-2 md:tw-w-1/2 md:tw-p-4"
        :class="`md:tw-order-${order[0]}`"
    >
      <h1 class="restauration-card-title">{{title}}</h1>
      <p class="restauration-card-text">{{text}}</p>
      <p class="restauration-card-text2" v-if="text2.length>0">{{text2}}</p>
      <a class="restauration-card-button" @click="download_file">
        {{buttonTitle}}
      </a>

    </div>
    <img :src="require(`@/assets/images/${img}`)" class="restauration-image tw-w-auto tw-order-1 md:tw-p-4 md:tw-w-1/2" :class="`md:tw-order-${order[1]}`"  alt="Guide acheteur" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RestaurationCard",
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    text2: {
      type: String,
      default: ''
    },
    buttonTitle: {
      type: String,
      default: ''
    },
    fileToDownload: {
      type: String,
      default: ''
    },
    order: {
      type: Array,
      default: () => [1,2],
    },
  },
  methods: {
    download_file() {
      axios({
        url: `${this.$app_url}/file/${this.fileToDownload}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const name = this.fileToDownload === 'guide_acheteur' ? 'Guide acheteur' : 'Livret recettes';
        link.setAttribute('download', `${name}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  }

}
</script>

<style scoped>

</style>