<template>
  <div id="concours-form">
    <v-dialog
        overlay-color="#151F6D"
        v-model="sentModal"
        persistent
        transition="dialog-top-transition"
        width="70%"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <p class="form-modal-title">
          Merci pour votre participation au programme concours photos<br>
          “Les produits laitiers & la restauration scolaire”.
        </p>
        <p class="form-modal-text">
          Votre participation est bien prise en compte.
        </p>
        <PrimaryButtonComponent @click="button_retour_modal" class="form-modal-button">
          Retour
        </PrimaryButtonComponent>
      </div>
    </v-dialog>

    <v-dialog
        overlay-color="#151F6D"
        v-model="kitModal"
        persistent
        transition="dialog-top-transition"
        width="70%"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <p class="form-modal-title">
          Concours terminé !<br>
        </p>
        <p class="form-modal-text">
          À bientôt, pour une nouvelle campagne avec nos partenaires.
        </p>
        <PrimaryButtonComponent @click="$router.push({ name: 'Public.Home' })" class="form-modal-button">
          Retour
        </PrimaryButtonComponent>
      </div>

    </v-dialog>

    <v-container class="concours-form-container">
      <PrimaryTitle title="Remplissez le formulaire pour participer"/>
      <div class="tw-flex tw-flex-col md:tw-flex-row">
        <div class="tw-flex tw-flex-col md:tw-w-1/2 mdtw-mx-8">
          <h5 class="form-guide-title">Renseignez vos informations :</h5>
          <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-6">
            <TextFieldComponent
                :error="!!validation_form.firstName"
                class="tw-w-full md:tw-w-1/2"
                v-model="concours_form.firstName"
                title="Prénom*"
                label="John"
            />
            <TextFieldComponent
                :error="!!validation_form.lastName"
                class="tw-w-full md:tw-w-1/2"
                v-model="concours_form.lastName"
                title="Nom*"
                label="Doe"
            />
          </div>
          <TextFieldComponent
              :error="!!validation_form.email"
              class="tw-w-full md:tw-w-2/3"
              v-model="concours_form.email"
              title="E-mail*"
              label="jdoe@email.com"
          />
          <h5 class="form-guide-title">Uploadez vos photos* :</h5>
          <UploadFileComponent
              @sendFile="handleSelectedFile"
              @removeFile="handleSelectedFile"
              :error="!!validation_form.files || !!validation_form['files.0'] || !!validation_form['files.1'] || !!validation_form['files.2']"
              class="tw-w-full"
              title="Jusqu’à 3 photos (jpeg ou png). Le poid des images ne doit pas dépasser 2Mo."
              :removeItems="removeUploadComponent"
          />
        </div>
        <div class="tw-flex tw-flex-col md:tw-w-1/2 md:tw-mx-8">
          <h5 class="form-guide-title">Renseignez l’adresse de l’établissement :</h5>
          <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-6">
            <SelectFieldComponent
                :error="!!validation_form.type"
                class="tw-w-1/2 md:tw-w-1/3"
                :items="typeItems"
                v-model="concours_form.type"
                title="Type*"
                label="Collège"
            />
            <TextFieldComponent
                :error="!!validation_form.department"
                class="tw-w-full md:tw-w-2/3"
                v-model="concours_form.department"
                title="Nom de l'établissement scolaire*"
                label="Doe"
            />
          </div>
          <TextFieldComponent
              :error="!!validation_form.department_address"
              class="tw-w-full"
              v-model="concours_form.department_address"
              title="Adresse de l'établissement scolaire*"
              label="Adresse"
          />
          <TextFieldComponent
              :error="!!validation_form.compl_info"
              class="tw-w-full"
              v-model="concours_form.compl_info"
              title="Informations complémentaires"
              label="Adresse"
          />
          <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-14">
            <TextFieldComponent
                :error="!!validation_form.postal_code"
                class="tw-w-full md:tw-w-2/6"
                v-model="concours_form.postal_code"
                title="Code postal*"
                label="XXXXX"
            />
            <TextFieldComponent
                :error="!!validation_form.city"
                class="tw-w-full md:tw-w-3/6"
                v-model="concours_form.city"
                title="Ville*"
                label="Ville"
            />
          </div>
        </div>
      </div>
    </v-container>
    <LineCniel class="tw-mx-12"/>
    <v-container class="concours-form-container">
      <span class="required-fields-text">*Champs obligatoires.</span>
      <v-checkbox class="checkbox-form" color="#151F6D" v-model="concours_form.accept_condition">
        <template v-slot:label>
          <div class="required-fields-text"
               :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
          >
            En cochant cette case, vous certifiez être informé(e) et acceptez la Politique de protection des données personnelles
            et les traitements réalisés par le CNIEL sur les données me concernant.
             Pour en savoir plus et pour exercer vos droits,
            reportez-vous à la notice ci-jointe >
            <a
                style="color: #151F6D"
                :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
                class="tw-underline tw-font-bold"
                href="./download/Politique de protection des données personnelles.pdf"
                @click.stop
                download>
              Gestion de vos données personnelles.*
            </a>
          </div>
        </template>
      </v-checkbox>
    </v-container>
    <v-container class="concours-form-container">
      <div class="tw-flex tw-flex-col tw-items-center">
        <span v-if="formHasError" class="error-fields-text">Vous devez renseignez tous les champs oblogatoires.</span>
        <PrimaryButtonComponent
            :loading="isSendingForm"
            @click="submit"
            class="submit-button"
        >
          Envoyer
        </PrimaryButtonComponent>
        <a href="download/règlement_concours.pdf" class="download-reglement-button" download>
          Voir le règlement du concours
        </a>
      </div>
    </v-container>
  </div>
</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import LineCniel from "../../../components/common/LineCniel";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import UploadFileComponent from "../../../components/common/UploadFileComponent";
import {postConcours} from "../services/appApi";
export default {
  name: "ConcoursForm",
  components: {
    UploadFileComponent,
    PrimaryButtonComponent, LineCniel, TextFieldComponent, SelectFieldComponent, PrimaryTitle},

  mounted() {
    this.kitModal = true;
  },

  data() {
    return {
      typeItems: ['Collège', 'Lycée', 'Lycée/Collège'],
      isSendingForm: false,
      sentModal: false,
      kitModal: false,
      existOrderModal: false,
      formHasError: false,
      removeUploadComponent: false,
      concours_form: {
        lastName: '',
        firstName: '',
        email: '',
        files: null,
        fileNumber: 0,
        type: 'Collège',
        department: '',
        department_address: '',
        compl_info: '',
        postal_code: '',
        city: '',
        accept_condition: false,
      },
      validation_form: {
        lastName: null,
        firstName: null,
        email: null,
        files: null,
        fileNumber: null,
        type: null,
        department: null,
        department_address: null,
        compl_info: null,
        postal_code: null,
        city: null,
        accept_condition: null,
      },
    }
  },

  methods: {

    handleSelectedFile(file) {
      if (file !== 'deleted') {
        this.concours_form.files = file;
      }
      else {
        this.concours_form.files = null;
      }
    },

    submit() {
      this.reset_validation();
      this.isSendingForm = true;
      let data = this.concours_form;
      if (data.compl_info === "") {
        delete data.compl_info;
      }
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      if (this.concours_form.files) {
        for( var i = 0; i < this.concours_form.files.length; i++ ){
          let file = this.concours_form.files[i];
          formData.append('files[' + i + ']', file);
        }
      }
      postConcours(formData)
          // eslint-disable-next-line no-unused-vars
          .then(resp => {
            // this.sentModal = true;
            this.isSendingForm = false;
            this.kitModal = true;
          })
          .catch(({response}) => {
            this.validation_form = response.data.errors;
            response.data.errors ? this.formHasError = true : false;
            this.isSendingForm = false;
          });
    },

    reset_validation() {
      this.formHasError = false;
      this.validation_form = {
        gender: null,
        lastName: null,
        firstName: null,
        email: null,
        files: null,
        fileNumber: null,
        type: null,
        department: null,
        department_address: null,
        compl_info: null,
        postal_code: null,
        city: null,
        accept_condition: null,

      };
    },

    button_retour_modal() {
      this.concours_form = {
        lastName: '',
        firstName: '',
        email: '',
        files: null,
        fileNumber: 0,
        type: 'Collège',
        department: '',
        department_address: '',
        compl_info: '',
        postal_code: '',
        city: '',
        accept_condition: false,
      };
      this.sentModal = false;
      this.removeUploadComponent = true;
      this.reset_validation();
      window.scrollTo(0,0);
    }
  }

}
</script>
