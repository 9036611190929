<template>
  <div class="tw-flex tw-flex-col" id="hero-banner">
    <img src="@/assets/images/hero-banner.svg" class="tw-hidden md:tw-block" alt="footer image" />
    <img src="@/assets/images/hero-banner-mobile.svg" class="md:tw-hidden" alt="footer image" />
    <a @click="open_qustionnaire" class="md:tw-hidden">
      <img src="@/assets/images/btn-qst-sm.svg" class="tw-cursor-pointer btn-qst-mobile" />
    </a>

    <v-container>
      <div class="hero-text-container">
        <p class="concours-title">
          Participez à notre concours photos<br>
          et tentez de remporter un des nombreux cadeaux !
        </p>
        <p class="concours-paragraph">
          C'est simple : envoyez-nous 3 photos du kit de communication mis en scène dans votre établissement avant le
          <strong>24 novembre 2021.</strong><br>
          5 établissements seront tirés au sort parmi les participants.<br>
          Pourquoi pas le vôtre ?
        </p>
        <p class="concours-choix tw-mt-8">
          — 1er et 2ème prix —<br>
          Une mallette de couteaux d'une valeur de 235€ et 2 places pour le salon de l’agriculture
        </p>
        <p class="concours-choix tw-mt-6">
          — 3ème au 5ème prix —<br>
          Des invitations pour le salon de l'agriculture et de nombreux goodies !
        </p>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeroBannerConcours",
  components: {},
  methods: {
    open_qustionnaire() {
      window.open('https://occurrence-survey.com/emf-dairy-restauration-scolaire-2021/accueil.php', '_blank');
    },
  }
}
</script>

<style scoped>

</style>
