<template>
  <v-app>
    <SnackbarComponent/>
    <router-view class="tw-bg-white"></router-view>
  </v-app>
</template>

<script>
import SnackbarComponent from "./components/common/SnackbarComponent";
import axios from "axios";

export default {
  name: 'App',
  mounted() {
    let width = window.innerWidth;
    if(!sessionStorage.getItem("SESSION")){
      const data = {width};
      axios.post(`${this.$app_url}/store-session`, data)
        // eslint-disable-next-line no-unused-vars
        .then(resp => {
          sessionStorage.setItem("SESSION", "EXIST");
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  components: {
    SnackbarComponent
  },
}
</script>