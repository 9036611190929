import Vuex from "vuex";
import Vue from "vue";
import ApiService from "../services/api.service";
import JwtService from "../services/jwt.service";
import createPersistedState from "vuex-persistedstate";
import router from "@/routes/router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        status: "",
        token: JwtService.getToken() || "",
        user: null,
        pre_loader: false,
        snackbar: {
            message: "",
            type: "",
        },
        notificationsTotal: "0",
        notificationsMessages: [],
    
        subcontractorsValid:[],
        subcontractorsInvalid:[],
        emailTemplate: null,
        oneSubcontractor:null,
    },

    getters: {
        getLoggedUser(state){
            return `${state.user.firstName}`;
        },
    },
    mutations: {
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, { token, user }) {
            state.status = "success";
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.token = "";
            state.user = null;
        },
        reset_auth(state) {
            JwtService.unsetToken();
            state.status = "";
            state.token = "";
            state.user = null;
        },
        set_auth(state, { user }) {
            state.user = user;
        },
        loader(state) {
            state.pre_loader = !state.pre_loader;
        },
        showAlertMessage(state, payload) {
            state.snackbar.message = payload.message;
            state.snackbar.type = payload.type;
        },
    },

    actions: {
        setToken({ commit }, token) {
            JwtService.setToken(token);
            ApiService.setHeader();
            ApiService.get("profile")
                .then(response => {
                    const user = response.data.data;
                    commit("auth_success", {token, user});
                })
                .catch(err => {
                    console.log("err => ", err);
                });
        },
        login({ commit }, userData) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                ApiService.post("auth/signin", userData)
                    .then(response => resolve(response))
                    .catch(error => {
                        commit("auth_error");
                        JwtService.unsetToken();
                        reject(error);
                    });

            });
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit("logout");
                JwtService.unsetToken();
                resolve();
                router.push({name: "LoginPage"}).then(() => localStorage.clear());
            });
        },
        loader({ commit }) {
            commit("loader");
        },
    },

    modules: {

    },

    plugins: [createPersistedState()],
});
