<template>
  <div id="concours">
    <HeroBannerConcours />
    <ConcoursForm />
  </div>
</template>

<script>
import HeroBannerConcours from "../components/HeroBannerConcours";
import ConcoursForm from "../components/ConcoursForm";
export default {
    name: "Concours",
  components: {ConcoursForm, HeroBannerConcours},
};
</script>
