<template>
  <div class="tw-flex tw-flex-col" id="hero-banner">
    <img src="@/assets/images/hero-banner.svg" class="tw-hidden md:tw-block" alt="footer image" />
    <img src="@/assets/images/hero-banner-mobile.svg" class="md:tw-hidden" alt="footer image" />
    <a @click="open_qustionnaire" class="md:tw-hidden">
      <img src="@/assets/images/btn-qst-sm.svg" class="tw-cursor-pointer btn-qst-mobile" />
    </a>

    <v-container>
      <div class="hero-text-container">
        <PrimaryTitle title="Bienvenue sur votre plateforme digitale"/>
        <PrimaryText paragraph="La filière laitière française, avec le soutien de l'Union Européenne, met à votre
        disposition un panel d'outils pour vous accompagner dans votre mission de
        restauration scolaire (à télécharger et commander)."/>
      </div>
    </v-container>
  </div>
</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle";
import PrimaryText from "../../../components/common/PrimaryText";
export default {
  name: "HeroBanner",
  components: {PrimaryText, PrimaryTitle},
  methods: {
    open_qustionnaire() {
      window.open('https://occurrence-survey.com/emf-dairy-restauration-scolaire-2021/accueil.php', '_blank');
    },
  }
}
</script>

<style scoped>

</style>