import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";
// import router from "@/routes/router"
import { serverConfig } from "@/config/serverConfig";

const ApiService = {
    init(){
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = this.$app_url;
        if(JwtService.getToken().token){
            Vue.axios.defaults.headers.common = {
                "X-Requested-With": "XMLHttpRequest",
                "Authorization":`Bearer ${JwtService.getToken().token}`,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            };
        }
    },

    setHeader(){
        Vue.axios.defaults.headers.common = {
            "X-Requested-With": "XMLHttpRequest",
            "Authorization":`Bearer ${JwtService.getToken().token}`,
            "Content-Type": "multipart/form-data"
        };
    },

    get(resource, slug = "") {
        if(slug!=""){
            return Vue.axios.get(`${serverConfig.appUrl}/${resource}/${slug}`).catch(error => {
                if (error.response.status === 401) {
                    // router.push({ name: 'LoginPage' })
                }
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            });
        }
        else{
            return Vue.axios.get(`${serverConfig.appUrl}/${resource}`).catch(error => {
                if (error.response.status === 401) {
                    // router.push({ name: 'LoginPage' })
                }
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            });
        }
    },

    post(resource, params) {
        return Vue.axios.post(`${serverConfig.appUrl}/${resource}`, params).catch(error => {
            console.log(error);
            console.log(error.response);
            if (error.response.status === 401) {
                // router.push({ name: 'LoginPage' })
            }
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    },

    put(resource, params) {
        return Vue.axios.put(`${serverConfig.appUrl}/${resource}`, params).catch(error => {
            if (error.response.status === 401) {
                // router.push({ name: 'LoginPage' })
            }
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    },

    delete(resource, params) {
        return Vue.axios.delete(`${serverConfig.appUrl}/${resource}`, params).catch(error => {
            if (error.response.status === 401) {
                // router.push({ name: 'LoginPage' })
            }
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    },
};

export default ApiService;
